.header_card {
  margin: -20rem 1rem !important;
  margin-bottom: -2em !important;
}
.header_icon {
  font-size: 4em;
  color: white;
}
.header_text {
  font-size: 1em;
  color: white;
}
.header_subtext {
  color: white;
}

.ant-card {
  border-radius: 8px !important;
}
.user_avatar {
  background: #f3679e;
}
.provider_avatar {
  background: #e47d3b;
}

// .users .xAxis .recharts-cartesian-axis-ticks g:first-child text {
//   fill: red !important;
//   stroke: #f5222d;
// }
// .users .xAxis .recharts-cartesian-axis-ticks g:nth-child(2) text {
//   fill: red !important;
//   stroke: #f5222d;
// }
