.user_add {
  font-size: 3em;
  border: 1px solid #ffffff;
  border-radius: 50%;
  padding: 0.5rem !important;
  color: #0e0e0e;
  background-color: white;
}
.user_add_position {
  float: right;
  margin-right: 6em;
  margin-top: -5.5em;
}

